 @import '../../../../SCSS/mixins';

 .Confirmation {
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0;
   left: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   z-index: 1000;
   background-color: #8a898966;
   backdrop-filter: blur(5px);

   .box {
     width: 90%;
     height: 80vh;
     border-radius: 16px;
     cursor: default;
     overflow: hidden;

     .video_box {
       width: 100%;
       height: 100%;
     }

     @include old1024 {
       height: 60vh;
     }

     @include mobileL {
       height: 300px;
     }
   }
 }