@import '../../SCSS/mixins';

.AboutCompany {
  @include mobileL {
    margin-top: 60px;
  }
}

.image_about_company {
  width: 100%;
  height: 600px;
  position: relative;

  & img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  & .description {
    position: absolute;
    width: 600px;
    bottom: 26px;
    right: 44px;
    color: rgb(255, 255, 255);
    @include manrope400;
    font-size: 34px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0px;
    text-align: right;
    text-transform: uppercase;
  }

  @include old1024 {
    height: 500px;

    & .description {
      font-size: 30px;
    }
  }

  @include tablet {
    height: 450px;

    & .description {
      width: 500px;
      font-size: 24px;
    }
  }

  @include mobileL {
    height: 350px;
    background: rgb(42, 42, 42);

    & .description {
      position: relative;
      top: 0;
      left: 0;
      width: 95%;
      font-size: 24px;
      margin: 0 auto;
      text-align: center;
    }
  }

  @include mobileM {
    height: 250px;

    & .description {
      font-size: 22px;
    }
  }

  @include mobileS {
    height: 200px;

    & .description {
      font-size: 18px;
    }
  }
}

.text_in {
  margin-top: 100px;

  @include mobileL {
    margin-top: 80px;
  }

  & p {
    color: rgb(34, 34, 34);
    @include manrope400;
    font-size: 24px;
    font-weight: 300;
    line-height: 160%;
    letter-spacing: -2.4%;
    text-align: left;
    white-space: break-spaces;

    @include mobileL {
      font-size: 18px;
    }
  }
}

.about_company_video {
  width: 100%;
  height: 700px;
  margin-top: 100px;
  display: flex;
  justify-content: center;

  @include oldMonitor {
    height: 650px;
  }

  @include old1024 {
    height: 600px;
  }

  @include tablet {
    height: 500px;
  }

  @include mobileL {
    margin-top: 16px;
    height: 400px;
  }

  @include mobileM {
    height: 300px;
  }

  & iframe {
    width: 85%;
    height: 100%;
    border-radius: 16px;

  }
}

.official_rep {
  margin: 80px 0;

  @include mobileL {
    margin: 16px 0;
  }

  & h2 {
    color: rgb(31, 31, 30);
    @include manrope400;
    font-size: 48px;
    font-weight: 300;
    line-height: 100%;
    letter-spacing: 2px;
    text-align: center;

    @include old1024 {
      font-size: 38px;
    }

    @include mobileL {
      font-size: 28px;
    }
  }
}

.reviews {
  margin: 80px 0;

  @include mobileL {
    margin: 16px 0;
  }

  & h2 {
    color: rgb(31, 31, 30);
    @include manrope400;
    font-size: 48px;
    font-weight: 300;
    line-height: 100%;
    letter-spacing: 2px;
    text-align: center;

    @include old1024 {
      font-size: 38px;
    }

    @include mobileL {
      font-size: 28px;
    }
  }
}