@import '../../../SCSS/mixins';

.Interested {
    height: 600px;
    width: 100%;
    background: rgb(174, 26, 26);
    background: linear-gradient(180deg, rgba(174, 26, 26, 1) 0%, rgba(54, 7, 7, 1) 100%);
    margin-top: 60px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;

        .logo {
            width: 75px;
            height: 75px;
        }

        .title {
            color: rgb(255, 255, 255);
            @include manrope400;
            font-size: 48px;
            line-height: 120%;
            letter-spacing: 0px;
            text-align: center;
            text-transform: uppercase;
        }

        .paragraf {
            color: rgb(255, 255, 255);
            @include manrope400;
            font-size: 16px;
            line-height: 160%;
            letter-spacing: 0px;
            text-align: center;
            width: 70%;
        }

        .contact {
            width: 277px;
            height: 46px;
            align-items: center;
            padding: 11px 24px 11px 24px;
            border-radius: 8px;
            background: rgb(255, 255, 255);
            color: rgb(31, 31, 31);
            @include manrope700;
            font-size: 15px;
            line-height: 158.7%;
            letter-spacing: -2%;
        }
    }

    .leftImg {
        position: absolute;
        left: 0;
        top: 30px;
        width: 660px;
        height: 480px;

    }

    .rightimg {
        position: absolute;
        top: 90px;
        right: 0;
        width: 300px;
        height: 400px;
    }

    @include oldMonitor {
        .leftImg {
            top: 170px;
            width: 400px;
            height: 300px;
        }

        .rightimg {
            width: 260px;
            height: 280px;
            top: 200px;
        }
    }

    @include tablet {
        height: 450px;

        .content {
            .title {
                font-size: 32px;
            }

            .paragraf {
                font-size: 14px;
            }
        }

        .leftImg {
            top: 190px;
            width: 180px;
            height: 180px;
        }

        .rightimg {
            width: 170px;
            height: 180px;
            top: 200px;
        }
    }

    @include mobileL {
        height: 350px;
        margin-top: 30px;

        .content {
            gap: 20px;

            .title {
                font-size: 20px;
            }

            .paragraf {
                font-size: 12px;
                width: 50%;
            }

            .contact {
                width: 150px;
                height: 40px;
                font-size: 12px;
            }
        }

        .leftImg {
            top: 140px;
            width: 180px;
            height: 180px;
        }

        .rightimg {
            width: 140px;
            height: 160px;
            top: 160px;
        }
    }
}