@import '../../SCSS/mixins';

.sub {
    display: flex;
    margin-top: 120px;

    .buttonBurger {
        display: none;
    }

    .reactIcons {
        display: none;
    }

    .subP {
        display: none;

    }

    @include mobileL {
        display: flex;
        flex-direction: column;
        margin-top: 80px;

        .buttonBurger {
            background-color: rgba(255, 255, 255, 0);
            display: flex;
            color: rgb(31, 31, 31);
            @include manrope600;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0%;
            text-align: left;

            .reactIcons {
                display: flex;
                margin-top: 3px;
            }
        }

        .subP {
            display: flex;
            justify-content: center;
            width: 100%;
            color: rgb(31, 31, 31);
            @include manrope400;
            font-size: 28px;
            line-height: 33px;
            letter-spacing: 0%;
            text-align: center;
            padding: 30px 0 15px 0;

            @include mobileL {
                font-size: 24px;
                padding: 15px 0 0px 0;
            }
        }
    }

    .subCat {
        width: 320px;
        height: 100%;
        padding-right: 25px;
        margin-top: 40px;

        @include mobileL {
            margin-top: 20px;
        }

        h2 {
            color: rgb(31, 31, 31);
            @include manrope700;
            font-size: 18px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0%;
            text-align: left;
            padding-top: 15px;

        }

        h3 {
            color: rgb(109, 109, 109);
            @include manrope400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0%;
            text-align: left;
            padding-left: 20px;
            padding-top: 10px;
        }
    }

    .sss {
        display: flex;
        width: 100%;
        flex-direction: column;

        .pName {
            margin-bottom: 30px;
        }

        @include mobileL {
            .pName {
                display: none;
            }
        }
    }

    .subCard {
        display: flex;
        gap: 50px;
        flex-wrap: wrap;
        width: 100%;

        @include mobileL {
            width: 100%;
        }

        .error {
            width: 100%;
            color: red;
            @include manrope400;
            font-size: 24px;
            text-align: center;
            padding-top: 80px;
        }
    }
}

.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}