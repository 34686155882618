@import '../../../SCSS/mixins';

.Advantages {
    margin: 80px 0;


    .titleBox {
        display: flex;
        justify-content: center;

        .title {
            color: rgb(31, 31, 30);
            @include manrope400;
            font-size: 48px;
            line-height: 56px;
            letter-spacing: 0%;
            text-align: center;
        }
    }

    .AdvantagesCard {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        margin-top: 56px;

        .line {
            border: 1px solid rgb(229, 229, 229);
            display: flex;
            flex-direction: column;
            height: 200px;
        }

        .card {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 280px;

            .img {
                width: 100px;
                height: 100px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .subtitle {
                color: rgb(23, 23, 23);
                @include manrope700;
                font-size: 20px;
                line-height: 28px;
                margin-top: 16px;
            }

            .parafraf {
                color: rgb(109, 109, 109);
                @include manrope400;
                font-size: 18px;
                line-height: 26px;
                margin-top: 12px;
            }
        }
    }

    @include oldMonitor {
        .AdvantagesCard {
            flex-wrap: wrap;
            row-gap: 40px;

            .line {
                display: none;
            }
        }
    }

    @include mobileL {
        margin: 30px 0;

        .AdvantagesCard {
            flex-direction: column;
            align-items: center;
        }

        .titleBox {
            .title {
                font-size: 32px;
            }
        }
    }
}