@import '.././../SCSS/mixins';

header {
    backdrop-filter: blur(20px);
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 5px solid rgb(157, 42, 22);
    z-index: 101;

    @include mobileL {
        border-bottom: 2px solid rgb(157, 42, 22);
    }

    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 3px;

        @include mobileL {
            padding: 0px 3px;
        }

        .leftContent {
            display: flex;
            align-items: center;
            gap: 50px;

            .antoicon {
                width: 75px;
                height: 75px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                @include mobileL {
                    width: 60px;
                    height: 60px;
                }
            }

            .menu {
                display: flex;
                gap: 50px;

                .menu_link {
                    color: rgb(255, 255, 255);
                    @include manrope700;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 143.7%;
                    cursor: pointer;
                }

                .active_nav {
                    color: rgb(157, 42, 22);
                }

                @include old1024 {
                    gap: 25px;

                    .menu_link {
                        font-size: 16px;
                    }
                }
            }
        }

        .rightContent {
            .phoneNumber {
                color: rgb(255, 255, 255);
                @include manrope600;
                font-size: 25px;
                font-weight: 600;
                line-height: 23.4px;
                letter-spacing: 0%;
                text-align: right;
                cursor: pointer;
            }

            @include old1024 {
                .phoneNumber {
                    font-size: 20px;
                }
            }

            @include mobileL {
                .phoneNumber {
                    font-size: 16px;
                }
            }
        }

        .nav_toogler {
            display: none;
            cursor: pointer;
        }

        .nav_toogler div {
            width: 1.7rem;
            height: 0.15rem;
            margin: 0.4rem;
            background-color: #ffffff;
            transition: 0.4s ease-in;
        }

        @include mobileL {
            .leftContent {
                .menu {
                    display: none;
                }
            }

            .rightContent {
                // display: none;
                // font-size: 16px;
            }

            .nav_toogler {
                display: block;
            }

            .toogle .line1 {
                transform: rotate(-45deg) translate(-2px, 3px);
            }

            .toogle .line2 {
                opacity: 0;
            }

            .toogle .line3 {
                transform: rotate(45deg) translate(-9px, -10px);
            }
        }
    }
}