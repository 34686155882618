@import '../../../SCSS/mixins';

.HomeVideo {
    height: 900px;
    width: 100%;
    margin-top: 80px;
    display: flex;
    justify-content: center;

    .iframe {
        width: 85%;
        height: 100%;
        border-radius: 16px;
    }

    @include oldMonitor {
        height: 700px;
    }

    @include old1024 {
        height: 600px;
    }

    @include tablet {
        height: 500px;
    }

    @include mobileL {
        height: 400px;
    }

    @include mobileM {
        height: 300px;
    }
}