@import '../../../SCSS/mixins';

.BurgerMenu {
    width: 90%;
    height: 100%;
    backdrop-filter: blur(20px);
    background: rgba(0, 0, 0, 0.3);
    box-shadow: 2px 2px 6.1px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    z-index: 1200;
    top: 0;
    right: 0;
    transition: all .5s;
    display: none;

    @include mobileL {
        display: block;
    }

    .in_burger_menu {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 80px 20px;
        gap: 12px;
        overflow-y: auto;

        @include mobileL {
            gap: 10px;
        }

        ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            width: 100%;
            height: 100%;

            .menu_link {
                color: rgb(255, 255, 255);
                @include manrope700;
                font-size: 30px;
                font-weight: 700;
                line-height: 143.7%;

                @include mobileL {
                    font-size: 24px;
                }
            }

            .active_nav {
                color: rgb(157, 42, 22);
            }
        }

        .phoneNumber {
            color: rgb(255, 255, 255);
            @include manrope600;
            font-size: 30px;
            font-weight: 600;
            line-height: 23.4px;
            letter-spacing: 0%;
            text-align: right;

        }

        .close_icon {
            position: absolute;
            right: 3%;
            top: 13px;
            color: #fff;
            font-size: 35px;
        }
    }

}

.BurgerAni {
    right: -767px;
    top: 0;
    position: fixed;
    transition: all .5s;
    z-index: 200;
    display: none;

    @include mobileL {
        display: block;
    }
}