@import '../../../SCSS/mixins';

.bghome {
    height: 900px;
    z-index: 10;
    width: 100%;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    @include oldMonitor {
        height: 700px;
    }

    @include mobileL {
        height: 320px;
    }

    .contentBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        text-align: center;
        height: 900px;

        .topContent {

            .title {
                color: rgb(255, 255, 255);
                @include manrope400;
                font-size: 66px;
                line-height: 126.7%;
                letter-spacing: 3%;
            }


        }

        .centerContent {

            .paragraf {
                color: rgb(255, 255, 255);
                @include manrope400;
                font-size: 18px;
                line-height: 150%;
                letter-spacing: -3%;
            }
        }

        .bottomContent {
            display: flex;
            gap: 20px;

            .catalog {
                width: 189px;
                height: 46px;
                align-items: center;
                padding: 11px 24px 11px 24px;
                border-radius: 8px;
                background: rgb(255, 255, 255);
                color: rgb(31, 31, 31);
                @include manrope700;
                font-size: 15px;
                line-height: 158.7%;
                letter-spacing: -2%;
            }

            .contact {
                width: 189px;
                height: 46px;
                align-items: center;
                padding: 11px 24px 11px 24px;
                box-sizing: border-box;
                border: 2px solid rgb(255, 255, 255);
                border-radius: 8px;
                backdrop-filter: blur(10px);
                background: rgba(255, 255, 255, 0.01);
                color: rgb(255, 255, 255);
                @include manrope700;
                font-size: 15px;
                line-height: 158.7%;
                letter-spacing: -2%;
            }

            @include mobileS {
                .catalog {
                    width: 135px;
                    padding: 10px 10px;
                }


                .contact {
                    width: 135px;
                    padding: 10px 10px;
                }
            }
        }

        @include oldMonitor {
            height: 700px;

            .topContent {
                .title {
                    font-size: 55px;
                }
            }

        }

        @include tablet {
            .topContent {
                .title {
                    font-size: 40px;
                }
            }
        }


        @include mobileL {
            height: 350px;

            .topContent {
                .title {
                    font-size: 26px;
                }

            }

            .centerContent {
                .paragraf {
                    font-size: 14px;
                }
            }
        }

        @include mobileS {
            .topContent {
                .title {
                    font-size: 20px;
                }
            }
        }
    }



}