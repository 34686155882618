.partnerCon {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: 20px;
    margin: 60px 0 0 0;

    .partnerBox {
        flex-shrink: 0;
        display: flex;
        gap: 20px;
        counter-reset: item;
        justify-content: space-around;
        min-width: 100%;
        animation: scroll 20s linear infinite;

        .img {
            width: 140px;
            height: 130px;
        }
    }

    @keyframes scroll {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(calc(-100% - 20px));
        }
    }
}