@import '../../SCSS/mixins';

.catalog {
    margin-top: 120px;

    @include mobileL {
        margin-top: 80px;
    }

    .categoriesH {
        color: rgb(31, 31, 30);
        font-size: 48px;
        font-weight: 300;
        line-height: 100%;
        letter-spacing: -2px;
        text-align: center;
        @include manrope400;
        padding-bottom: 60px;

        @include mobileL {
            font-size: 32px;
            padding-bottom: 20px;
        }
    }

    .cardCategories {
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
    }
}