@import '../../SCSS/mixins';

.partners {
  margin-top: 50px;

  & h2 {
    color: rgb(31, 31, 30);
    @include manrope400;
    font-size: 48px;
    font-weight: 300;
    line-height: 100%;
    letter-spacing: -2px;
    text-align: center;
  }

  @include mobileL {
    margin-top: 30px;

    & h2 {
      font-size: 32px;
    }
  }
}