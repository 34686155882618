@import '../../../SCSS/mixins';


.card {
    display: flex;
    flex-direction: column;
    flex: 0 0 32%;

    @include old1024 {
        flex: 0 0 45%;
    }

    @include mobileL {
        flex: 0 0 100%;
    }

    width: 100%;
    height: 320px;
    border-radius: 16px;
    box-shadow: 4px 4px 14px 0px rgba(0, 0, 0, 0.25);
    background: rgb(255, 255, 255);
    margin-bottom: 40px;

    @include mobileL {
        margin-bottom: 20px;

    }

    .cardImg {
        width: 100%;
        height: 260px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 16px 16px 0 0;
            object-fit: cover;
            object-position: center;
        }
    }

    p {
        padding: 10px 0 0 20px;
        color: rgb(0, 0, 0);
        @include manrope400;
        font-size: 28px;
        line-height: 38px;
        letter-spacing: 0%;
        text-align: left;

        @include oldMonitor {
            font-size: 24px;
        }

        @include mobileL {
            font-size: 22px;
        }

        @include mobileM {
            font-size: 18px;
        }
    }

    transition: all .3s;

    &:hover {
        transition: all .3s;
        transform: scale(0.95);
    }
}