@import '../../../SCSS/mixins';

.coments_swiper {
  width: 100%;
  margin-top: 50px;

  & .swiper-wrapper {
    justify-content: center;

    @include mobileL {
      justify-content: flex-start;
    }
  }

  .mySwiper {
    width: 100%;
    height: 250px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;



    .card_in_comment {
      // width: 80%;
      display: flex;
      // margin-bottom: 40px;
      border-radius: 20px;
      background: rgb(249, 249, 249);
      box-shadow: 0 3px 10px #00000060;
      padding: 20px;
      flex-direction: column;
      gap: 20px;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }


      .info_in_commit {
        display: flex;
        gap: 20px;
      }

      & img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }

      .in_card_swiper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & h2 {
          color: rgb(29, 30, 37);
          @include manrope400;
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: -1px;
          text-align: left;
        }

        & span {
          color: rgb(134, 148, 158);
          @include manrope400;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0px;
          text-align: left;
        }
      }

      & .button_in_video {
        border-radius: 20px;
        background: #C31718;
        box-shadow: 0 0.1px 10px #00000060;
        padding: 16px;
        color: #fff;
        @include manrope400;
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -1px;
        text-align: center;
      }
    }

    .swiper-scrollbar-drag {
      background-color: #6D6D6D;
    }

    .swiper-pagination-bullet,
    .swiper-pagination-bullet-active,
    .swiper-pagination-bullet-active-main {
      background-color: #6D6D6D;
      width: 100px;
      border-radius: 5px;
    }
  }
}