@mixin newMonitor1440 {
  @media screen and (max-width:1440px) {
    @content;
  }
}

@mixin manrope400 {
  font-family: "Manrope", sans-serif;
}

@mixin manrope500 {
  font-family: "Manrope", sans-serif;
  font-weight: 500;
}

@mixin manrope600 {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}

@mixin manrope700 {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
}

@mixin MonitorAdi {
  @media screen and (max-width: 1520px) {
    @content;
  }
}

@mixin oldMonitor {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin old1024 {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin mobileL {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin mobileM {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin mobileS {
  @media screen and (max-width: 430px) {
    @content;
  }
}