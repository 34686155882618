@import '../../SCSS/mixins';

.Leasing {
  @include mobileL {
    margin-top: 60px;
  }
}

.image_about_company {
  width: 100%;
  height: 600px;
  position: relative;

  & img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  & .in_leasingImg {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;

    & .description {
      @include manrope400;
      color: rgb(255, 255, 255);
      font-size: 58px;
      font-weight: 300;
      line-height: 150%;
      letter-spacing: 2px;
      text-align: center;
      text-transform: uppercase;
      text-shadow:
        0 0 10px#000,
        0 0 15px#000,
        0 0 20px#000,
        0 0 25px#000,
        0 0 30px #000,
        0 0 35px #000,
        0 0 40px #000,
        0 0 45px #000,
        0 0 50px #000,
        0 0 55px #000,
        0 0 60px #000,
        0 0 65px #000,
        0 0 70px #000;
    }
  }

  @include old1024 {
    height: 500px;

    & .in_leasingImg {
      & .description {
        font-size: 48px;
      }
    }
  }

  @include tablet {
    height: 450px;
  }

  @include mobileL {
    height: 350px;
    background: rgb(42, 42, 42);

    & .in_leasingImg {
      & .description {
        font-size: 36px;
      }
    }
  }

  @include mobileM {
    height: 250px;

    & .in_leasingImg {
      & .description {
        font-size: 26px;
      }
    }
  }

  @include mobileS {
    height: 200px;

    & .in_leasingImg {
      & .description {
        font-size: 20px;
      }
    }
  }

}

.text_in {
  margin-top: 100px;

  & p {
    color: rgb(34, 34, 34);
    @include manrope400;
    font-size: 24px;
    font-weight: 300;
    line-height: 160%;
    letter-spacing: -2.4%;
    text-align: left;
    white-space: break-spaces;
  }

  & .fatty_text {
    color: rgb(34, 34, 34);
    @include manrope700;
    font-size: 24px;
    // font-weight: 300;
    line-height: 160%;
    letter-spacing: -2.4%;
    text-align: left;
    white-space: break-spaces;
  }

  @include old1024 {
    margin-top: 70px;
  }

  @include mobileL {
    margin-top: 26px;

    & p {
      font-size: 18px;
    }

    & .fatty_text {
      font-size: 18px;
    }
  }
}

.bank_images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
  gap: 50px;
  width: 67%;

  & .bank_image {
    width: 400px;
    height: 150px;
    border-radius: 8px;
    box-shadow: 4px 4px 14px 0px rgba(0, 0, 0, 0.25);
    background: rgb(255, 255, 255);
    padding: 25px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  @include oldMonitor {
    margin-bottom: 70px;
    margin-top: 70px;
    width: 100%;

    & .bank_image {
      width: 400px;
      height: 150px;
    }
  }

  @include old1024 {
    margin-top: 70px;

    & .bank_image {
      width: 290px;
      height: 120px;
    }
  }



  @include mobileL {
    margin-top: 26px;
    margin-bottom: 26px;
    gap: 16px;

    & .bank_image {
      width: 100%;
      height: 130px;
    }
  }

  @include mobileM {
    gap: 8px;

    & .bank_image {
      width: 100%;
    }

  }


}