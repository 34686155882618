@import '../../../SCSS/mixins';

.subCut,
.subCutOpen {
    h2 {
        color: rgb(31, 31, 31);
        @include manrope700;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0%;
        text-align: left;
        padding-top: 15px;

    }

    .not_active,
    .active {
        color: rgb(109, 109, 109);
        @include manrope400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0%;
        text-align: left;
        padding-left: 20px;
        padding-bottom: 10px;
        cursor: pointer;
    }

    .active {
        color: rgb(214, 15, 15);
        cursor: pointer;
    }
}

@include mobileL {
    .subCutOpen {
        position: fixed;
        width: 270px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 110px;
        left: -270px;
        background-color: rgb(255, 214, 0);
        border-radius: 16px;
        z-index: 1000;
        transition: all .5s;

    }

    .subCut {
        position: fixed;
        width: 270px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 110px;
        left: 0;
        background-color: rgb(255, 214, 0);
        border-radius: 16px;
        z-index: 1000;
        transition: all .5s;
        padding: 0 0 10px 20px;

        .not_active,
        .active {
            width: 250px;
        }
    }
}