@import '../../SCSS/mixins';

.detailView {
    margin-top: 120px;

    @include mobileL {
        margin-top: 80px;
    }
}

.pName {
    width: 72%;
    margin: 0 auto 20px;
    color: rgb(31, 31, 31);
    @include manrope400;
    font-size: 30px;
    line-height: 41px;
    letter-spacing: 0%;
    text-align: left;

    span {
        color: rgb(31, 31, 31);
        @include manrope700;
        font-size: 30px;
        line-height: 41px;
        letter-spacing: 0%;
        text-align: left;
    }

    @include mobileL {
        width: 90%;
        font-size: 22px;

        span {
            font-size: 22px;
        }
    }

    @include mobileM {
        width: 90%;
        font-size: 18px;

        span {
            font-size: 18px;
        }
    }
}

.displayOne {
    display: flex;
    // padding-top: 40px;
    justify-content: center;

    @include mobileL {

        flex-direction: column;
    }
}



.display {
    display: flex;
    flex-direction: row-reverse;


    width: 45%;

    @include oldMonitor {
        flex-direction: column;

    }

    @include mobileL {
        // flex-direction: column;
        width: 90%;
        margin: 0 auto;
    }

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }


    .swiper {
        width: 100%;
        height: 350px;
        margin-left: auto;
        margin-right: auto;
    }

    .swiper-slide {
        background-size: cover;
        background-position: center;
    }

    .mySwiper2 {
        height: 470px;
        width: 100%;
        box-shadow: 4px 4px 14px 0px rgba(0, 0, 0, 0.25);
        border-radius: 16px;

        .swiper-slide img {
            display: block;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 6px;
        }

        @include mobileM {
            height: 420px;
        }

        @include mobileM {
            height: 370px;
        }

        @include mobileM {
            height: 320px;
        }
    }

    .mySwiper {
        display: flex;
        width: 300px;
        height: 470px;
        box-sizing: border-box;

        @include oldMonitor {
            margin-top: 10px;
            width: 100%;
            height: 100px;
            display: flex;
            flex-direction: column-reverse;
            flex-wrap: wrap;
            align-content: space-between;
        }

        .swiper-wrapper {
            width: 170px;
            height: 140px;
        }

        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 6px;
        }
    }



    .mySwiper .swiper-slide {
        width: 100%;
        height: 100px;
        opacity: 0.4;
    }

    .mySwiper .swiper-slide-thumb-active {
        opacity: 1;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        // border-radius: 50px;
    }

}

.detail_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 30px;
    width: 30%;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgb(255, 221, 85);
        border-radius: 10px;

    }

    @include mobileL {
        // flex-direction: column;
        width: 90%;
        margin: 0 auto;
        padding-left: 0px;
        padding-top: 20px;
    }

    h2 {
        color: rgb(31, 31, 31);
        @include manrope700;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: 0%;
        text-align: left;
        padding-top: 0;
        width: 100%;
        min-height: 60px;
        max-height: 350px;
        overflow-y: auto;

        span {
            color: rgb(31, 31, 31);
            @include manrope400;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: 0%;
            text-align: left;
        }

        .line {
            margin-left: 5px;
            margin-right: 5px;
            color: rgb(31, 31, 31);
            @include manrope400;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: 0%;
            text-align: left;
        }


    }

    a {
        width: 100%;
        height: 70PX;
        border-radius: 16px;
        box-shadow: 4px 4px 14px 0px rgba(0, 0, 0, 0.25);
        background: rgb(255, 221, 85);
        display: flex;
        justify-content: center;
        align-items: center;

        @include mobileL {
            // flex-direction: column;
            // width: 90%;
            // margin: 0 auto;
            margin-top: 20px;
        }

        span {
            color: rgb(31, 31, 31);
            @include manrope700;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: 0%;
            text-align: center;
        }
    }
}

.Specifications {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;

    h2 {
        width: 100%;
        text-align: center;
        color: rgb(31, 31, 31);
        @include manrope700;
        font-size: 30px;
        line-height: 41px;
        letter-spacing: 0%;
        padding-bottom: 40px;

        @include mobileL {
            font-size: 22px;
            padding-bottom: 20px;
        }

        @include mobileM {
            font-size: 18px;
        }
    }

    .twoDiv {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .firstCard {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mobileL {
            width: 90%;
        }

        h3 {
            color: rgb(0, 0, 0);
            @include manrope700;
            font-size: 24px;
            line-height: 33px;
            letter-spacing: 0%;
            text-align: center;
            padding-bottom: 30px;

            @include mobileL {
                font-size: 18px;
            }

            @include mobileM {
                font-size: 15px;
            }
        }

        .firstCardBottom {
            width: 100%;
            border-radius: 8px;
            padding: 10px;
            box-shadow: 4px 4px 14px 0px rgba(0, 0, 0, 0.25);
            background: rgb(255, 255, 255);

            div {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                h4 {
                    color: rgb(0, 0, 0);
                    @include manrope400;
                    font-size: 18px;
                    line-height: 25px;
                    letter-spacing: 0%;
                    text-align: left;
                    padding-bottom: 10px;

                    @include mobileL {
                        font-size: 15px;
                    }
                }

                h5 {
                    color: rgb(0, 0, 0);
                    @include manrope700;
                    font-size: 18px;
                    line-height: 25px;
                    letter-spacing: 0%;

                    @include mobileL {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    .iframe {
        display: flex;
        justify-content: center;
        margin-top: 70px;

        .ifrmB {
            width: 85%;
            height: 500px;
            border-radius: 16px;

            @include mobileL {
                height: 400px;
                width: 90%;
            }

            @include mobileM {
                height: 300px;
            }
        }
    }

}