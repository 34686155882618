@import '../../SCSS/mixins';

footer {
    border-top: 1px solid black;
    margin-top: 60px;

    .footerNav {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 32px 0px;

        .topBox {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .leftItem {
                width: 75px;
                height: 75px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .topItem {
                .menu {
                    display: flex;
                    align-items: center;
                    gap: 50px;

                    .menu_link {
                        color: rgb(31, 31, 30);
                        @include manrope500;
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 143.7%;
                    }
                }
            }

            .rightItem {
                display: flex;
                align-items: center;
                gap: 25px;

                .img {
                    width: 40px;
                    height: 40px;
                }

                @include mobileL {

                    .img {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }

        .bottomBox {
            .paragraf {
                color: rgb(31, 31, 30);
                @include manrope400;
                font-size: 14px;
                font-weight: 400;
                line-height: 160%;
            }
        }

        @include mobileL {
            .topBox {
                flex-direction: column;
                gap: 20px;

                .topItem {
                    .menu {
                        flex-wrap: wrap;
                        justify-content: center;
                        gap: 10px;
                    }
                }
            }

            .bottomBox {
                display: flex;
                justify-content: center;
            }
        }
    }
}