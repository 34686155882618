@import '../../SCSS/mixins';

.contacts_section {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  margin-top: 80px;

  @include mobileL {
    margin-top: 30px;
    gap: 20px;
  }

  & .Contacts {
    color: rgb(0, 0, 0);
    @include manrope400;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -2px;
    text-align: center;

    @include oldMonitor {
      font-size: 34px;
    }

    @include tablet {
      font-size: 32px;
    }

    @include mobileL {
      font-size: 28px;
    }
  }

  & .image_in {
    width: 100%;
    height: 300px;

    @include tablet {
      height: 250px;
    }

    @include mobileL {
      height: 180px;
    }

    & .maps_card {
      width: 100%;
      height: 100%;
    }
  }

  & .all_address {
    display: flex;
    justify-content: space-between;

    & .address {
      width: 27%;

      & a {
        color: rgb(23, 23, 23);
        @include manrope400;
        font-size: 24px;
        font-weight: 600;
        line-height: 130%;
        letter-spacing: 0%;
        text-align: left;

        @include oldMonitor {
          font-size: 20px;
        }

        @include tablet {
          font-size: 18px;
        }
      }
    }

    & .phone_number {
      width: 20%;
      display: flex;
      flex-direction: column;

      & a {
        color: rgb(31, 31, 30);
        font-size: 28px;
        @include manrope600;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: 0%;

        @include oldMonitor {
          font-size: 20px;
        }

        @include tablet {
          font-size: 18px;
        }
      }

      & .days_week {
        color: rgb(31, 31, 30);
        @include manrope400;
        font-size: 12px;
        font-weight: 600;
        line-height: 15.6px;
        letter-spacing: 0%;
      }
    }

    & .mail {
      & a {
        @include manrope400;
        color: rgb(31, 31, 30);
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0%;
      }
    }

    @include mobileL {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .address,
      .phone_number,
      .mail {
        width: 100%;
      }
    }
  }
}