@import '../../../SCSS/mixins';

.PsCon {
    margin-top: 50px;
}

.volvo {
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.PartnerSlider {
    height: 620px;
    width: 100%;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    @include oldMonitor {
        height: 500px;
    }

    @include mobileL {
        height: 300px;
    }

    @include mobileS {
        height: 200px;
    }

    .PartnerSliderBox {
        height: 500px;
        display: flex;
        align-items: end;

        .title {
            width: 40%;
            @include manrope400;
            font-size: 48px;
            font-weight: 200;
            line-height: 120%;
            letter-spacing: -2px;
            color: #fff;
            text-align: left;
            text-transform: uppercase;
        }

        @include oldMonitor {
            height: 400px;

            .title {
                font-size: 40px;
            }
        }

        @include mobileL {
            height: 270px;

            .title {
                font-size: 22px;
                width: 50%;
            }
        }

        @include mobileS {
            height: 180px;

            .title {
                font-size: 20px;
            }
        }
    }
}