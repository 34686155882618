@import '../../../SCSS/mixins';


.EquipmentStock {
    margin-top: 28px;

    .titleBox {
        display: flex;
        justify-content: center;

        .title {
            color: rgb(0, 0, 0);
            @include manrope400;
            font-size: 48px;
            line-height: 56px;
        }
    }

    .cardBox {
        margin-top: 30px;

        .card {
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            .content {
                .title {
                    color: rgb(109, 109, 109);
                    @include manrope400;
                    font-size: 40px;
                    line-height: 140%;
                    letter-spacing: 0px;
                    text-align: left;
                }

                .subtitle {
                    color: rgb(109, 109, 109);
                    @include manrope400;
                    font-size: 40px;
                    line-height: 140%;
                    letter-spacing: 0px;
                    text-align: left;
                }
            }

            .img {
                height: 600px;
                width: 100%;
                margin: 20px 0;

                img {
                    width: 100%;
                    height: 100%;
                    object-position: center;
                    object-fit: contain;
                }
            }

            .paragraf {
                color: rgb(109, 109, 109);
                @include manrope400;
                font-size: 30px;
                line-height: 140%;
                letter-spacing: 0px;
                text-align: left;
            }

            @include tablet {
                .content {
                    .title {
                        font-size: 30px;
                    }

                    .subtitle {
                        font-size: 30px;
                    }
                }

                .img {
                    height: 500px;
                }

                .paragraf {
                    font-size: 16px;
                }
            }

            @include mobileL {
                .content {
                    .title {
                        font-size: 22px;
                    }

                    .subtitle {
                        font-size: 22px;
                    }
                }

                .img {
                    height: 300px;
                }

                .paragraf {
                    font-size: 16px;
                }
            }

            @include mobileS {
                .img {
                    height: 200px;
                }

                .content {
                    .title {
                        font-size: 20px;
                    }

                    .subtitle {
                        font-size: 20px;
                    }
                }


                .paragraf {
                    font-size: 14px;
                }
            }
        }
    }

    @include mobileL {
        .titleBox {
            .title {
                font-size: 32px;
            }
        }
    }
}