@import '../../../SCSS/mixins';

.card {
    display: flex;
    flex-direction: column;
    flex: 0 0 30%;

    @include MonitorAdi {
        flex: 0 0 46%;
    }

    @include old1024 {
        flex: 0 0 100%;
    }


    width: 100%;
    height: 490px;
    border-radius: 16px;
    box-shadow: 4px 4px 14px 0px rgba(0, 0, 0, 0.25);
    background: rgb(255, 255, 255);
    // margin-bottom: 40px;

    .cardImg {
        width: 100%;
        height: 350px;
        position: relative;

        img {
            border-radius: 16px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        button {
            position: absolute;
            top: 20px;
            left: 20px;
            width: 80px;
            height: 29px;
            border-radius: 8px;
            background: rgb(255, 214, 0);

            span {
                color: rgb(31, 31, 31);
                @include manrope600;
                font-size: 14px;
                line-height: 19px;
                letter-spacing: 0%;
                text-align: left;
            }
        }
    }

    p {
        color: rgb(31, 31, 31);
        @include manrope600;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0%;
        text-align: left;
        padding: 18px 0 30px 18px;
    }

    .center {
        display: flex;
        justify-content: center;

        button {
            width: 80%;
            height: 47px;
            border-radius: 8px;

            background: rgb(255, 214, 0);
        }
    }

}