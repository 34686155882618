@import '../../../../SCSS/mixins';

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    min-width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(128, 126, 126, 0.5);
    backdrop-filter: blur(5px);
    cursor: pointer;

    .Contactbox {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 14px;
        height: 30vh;
        border-radius: 20px;
        background: #0302025c;
        border: 3px solid rgb(157, 42, 22);
        padding: 20px;

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            .whs {
                height: 60px;
                width: 230px;
                border-radius: 8px;
                background-color: #25d366;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
            }

            .tel {
                height: 60px;
                width: 230px;
                border-radius: 8px;
                background-color: #34b7f1;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
            }

            .inst {
                height: 60px;
                width: 230px;
                border-radius: 8px;
                background: #833ab4;
                background: linear-gradient(to right,
                        #833ab4, #fd1d1d, #fcb045);
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
            }

            .title {
                font-size: 20px;
                color: #fff;
                @include manrope500;
            }

            .img {
                width: 40px;
                height: 40px;
            }
        }
    }
}