@import '../../../SCSS/mixins';

.PeopleChoiceBox {
    height: 680px;
    margin-bottom: 100px;
    margin-top: 100px;

    .PCtitleBox {
        display: flex;
        justify-content: center;

        .PCtitle {
            color: rgb(31, 31, 30);
            @include manrope400;
            font-size: 48px;
            line-height: 56px;
            letter-spacing: 0%;
            text-align: center;
        }
    }

    @include mobileL {
        height: 350px;
        // margin: 0 50px;

        .PCtitleBox {
            .PCtitle {
                font-size: 32px;
            }
        }
    }

    @include mobileS {
        height: 300px;
    }
}

.swiper_in_home {
    height: 100%;

    :root {
        --swiper-navigation-color: #000000; // Цвет стрелок красный
        --swiper-theme-color: #000; // Цвет стрелок красный, если используется тема Swiper
        --swiper-pagination-color: #000;
        --swiper-theme-color: #000;
        --swiper-pagination-bullet-size: 6px;
        --swiper-navigation-size: 24px;
    }

    .mySwiper {

        .swiper-pagination-bullet,
        .swiper-pagination-bullet-active,
        .swiper-pagination-bullet-active-main {
            background-color: #6D6D6D;
            width: 100px;
            border-radius: 5px;
            height: 5px;

            @include mobileL {
                width: 75px;
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        color: var(--swiper-navigation-color) !important; // Цвет стрелок с приоритетом
        width: var(--swiper-navigation-width); // Ширина стрелок
        height: var(--swiper-navigation-height); // Высота стрелок
        font-size: var(--swiper-navigation-size);
    }

    .swiper-pagination-bullet-active {
        background-color: #000;
    }

    .swiper-pagination-bullet {
        height: var(--swiper-pagination-bullet-size); // Высота точек пагинации
        width: var(--swiper-pagination-bullet-size);
    }

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        // background: #fff;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .PCcard {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .PCimg {
            height: 500px;
            width: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .PCsubtitle {
            color: rgb(31, 31, 31);
            @include manrope700;
            font-size: 20px;
            line-height: 140%;
            letter-spacing: 0px;
            text-align: center;
        }

        @include mobileL {
            .PCimg {
                height: 200px;
            }

            margin-top: 20px;
        }

        @include mobileS {
            .PCimg {

                img {
                    object-fit: contain;
                }
            }
        }
    }
}